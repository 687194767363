.custom-card {
    max-width: 800px;
    width: 100%;
    margin: auto;
    background-color: #211f23;
    border-radius: 10px;
    border: 1px solid #74737a;
    padding: 25px;
}

.custom-card .head-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.custom-card .head-row > div {
    display: flex;
    align-items: center;
}
.custom-card .head-row .badge {
    padding: 5px 15px;
    border-radius: 20px;
    color: #fff;
    word-wrap: normal;
    white-space: nowrap;
}
.custom-card .head-row img {
    width: 30px;
    height: 100%;
    margin-right: 6px;
    margin-left: -6px
}
.custom-card h2 {
    font-size: 2rem;
}
.custom-card p {
    color: #8d898e;
    font-size: 1rem;
}
.custom-card ul {
    list-style-type: none;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.custom-card ul li {
    font-size: 1.1rem;
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    padding: 9px 0;
    text-align: left;
    align-items: center;
}
.custom-card ul li.check {
    background-color: #2d2d2d;
    border-radius: 5px;
}
.custom-card ul li span {
    color: #8d898e;
}
.custom-card ul li:last-child {
    margin-bottom: 0;
}
.custom-card .check-icon {
    width: 15px;
    height: 15px;
    background-size: cover;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 10px;
}

.percentage {
    white-space: nowrap;
}

@media screen and (max-width: 776px) {
    .custom-card h2 {
        font-size: 1.5rem;
    }
    .custom-card ul li {
        font-size: 0.9rem;
    }
}