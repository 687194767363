.show-counter {
    display: flex;
    gap: 15px;
    font-size: 20px;
    align-items: center;
}
.show-counter > div {
    display: flex;
    flex-direction: column;
    position: relative;
}
.show-counter .values {
    font-size: 23px;
}
.show-counter .numbers {
    font-size: 22px;
}

.show-counter .dots {
    font-size: 35px;
}

@media screen and (max-width: 776px) {
    .show-counter {
        width: 100%;
        gap: 0;
        justify-content: space-between;
    }
}